unit login_Unit1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.REST, WEBLib.Storage;

type
  TForm1 = class(TForm)
    toasterDiv: THTMLDiv;
    xloginDiv: THTMLDiv;
    LoginButton: TButton;
    emailEdit: TEdit;
    passwordEdit: TEdit;
    WebRESTClient1: TRESTClient;
    WebSessionStorage1: TSessionStorage;
    userID1Label: TLabel;
    current_functionLabel: TLabel;
    WebButton1: TButton;
    LogOutSpan: THTMLSpan;
    UserID2: TLabel;
    WebLabel1: TLabel;
    WebLabel4: TLabel;
    WebLabel3: TLabel;
    procedure LoginButtonClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebRESTClient1RequestResponse(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure WebButton1Click(Sender: TObject);
    procedure LogOutSpanClick(Sender: TObject);
    procedure UserID2Click(Sender: TObject);
    procedure WebLabel1Click(Sender: TObject);
    procedure WebLabel2Click(Sender: TObject);
    procedure WebLabel3Click(Sender: TObject);
    procedure WebLabel4Click(Sender: TObject);
  private
    { Private declarations }
    url :string;
    isFullscreen : boolean;
    SessionID:string;
    ppp :string;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

procedure TForm1.LoginButtonClick(Sender: TObject);
var json, help: string;
begin

  if ((emailEdit.Text = '')  or  (passwordEdit.Text = '')) then begin

     showmessage( 'ID or Password error.');

     exit;
  end;

     json :=   '{"name":"'+ emailEdit.Text +
     '","pass":"'+ passwordEdit.Text +

     '"}'    ;

   WebRESTClient1.HttpsPost(url + 'm_loginppp.php',
     'application/json', json );




end;

procedure TForm1.LogOutSpanClick(Sender: TObject);
begin
   WebSessionStorage1.SetValue('session','');
       //權限
       ppp := '';
       WebSessionStorage1.SetValue('ppp',ppp);

       WebSessionStorage1.SetValue('uname','' );

       userID1Label.Caption := 'ID:' + '';
       //UserID2.Caption := 'ID:' + '';

        toasterDiv.Visible := False;
        xloginDiv.Visible := True;
end;

procedure TForm1.UserID2Click(Sender: TObject);
var  sid:string;
begin

  if (pos('up',ppp) > 0  ) or  (pos('ca',ppp) > 0  ) then begin
       sid := SessionID;

      current_functionLabel.Caption := '資料匯入、修改';
      asm

      document.getElementById('runtimepage').src = 'inventory_keyin.html?id='+ sid ;

      end;
  end else begin
     showmessage('沒有權限!');
     exit;

  end;





end;

procedure TForm1.WebButton1Click(Sender: TObject);
var run:boolean;
begin


  run := isFullscreen;

  if run then begin

     asm
      var elem = document.documentElement;
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }

     end;

     isFullscreen := false;

  end else begin

      asm
         var elem = document.documentElement;

            if (elem.requestFullscreen) {
              elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
              elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
              elem.msRequestFullscreen();
            }
      end;

      isFullscreen := true;
  end;

end;

procedure TForm1.WebFormCreate(Sender: TObject);
var  host : string;
begin

    asm
        host = window.location.host;
    end;

  if host = 'localhost:8000' then begin
     // Debug

     url := 'http://192.168.100.51/ST2/php/' ;
     //url := 'http://192.168.100.50/MES/php/' ;
     //SessionID := 'AF025A121FA2957F0268F91D05EA3D51';
  end else begin
     url :=  'https://' + host + '/php/';
     //SessionID := WebSessionStorage1.GetValue( 'SessionID' );
  end;

end;

procedure TForm1.WebLabel1Click(Sender: TObject);
var  sid:string;
begin
 sid := SessionID;

 current_functionLabel.Caption := '資料查詢';
  asm


    document.getElementById('runtimepage').src = 'inventory_query.html?id='+ sid ;

  end;

end;

procedure TForm1.WebLabel2Click(Sender: TObject);
var  sid:string;
begin
 sid := SessionID;

 current_functionLabel.Caption := '資料匯入';

 showmessage('建構中...');
  asm

   // document.getElementById('runtimepage').src = 'excel_up.html?id='+ sid ;


  end;

end;

procedure TForm1.WebLabel3Click(Sender: TObject);
var  sid:string;
begin
  if pos('ev',ppp) <= 0 then begin
     showmessage('沒有權限!');
     exit;
  end;
 sid := SessionID;

 current_functionLabel.Caption := '事件紀錄';
 //showmessage('建構中...');
  asm


    document.getElementById('runtimepage').src = 'logo.html?id='+ sid ;

  end;

end;

procedure TForm1.WebLabel4Click(Sender: TObject);
var  sid:string;
begin

  if pos('ur',ppp) <= 0 then begin
     showmessage('沒有權限!');
     exit;
  end;

 sid := SessionID;

 current_functionLabel.Caption := '使用者管理';
  asm

    document.getElementById('runtimepage').src = 'set_User.html?id='+ sid ;
  end;

end;

procedure TForm1.WebRESTClient1RequestResponse(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; AResponse: string);

var  sid , u ,p:string;
     mr: TModalResult;
     vtablet : TJSArray;
     groupid, mode:string;
begin
  //showmessage(AResponse);

  if pos('error',AResponse) > 0 then begin
     showmessage('ID or Password error.');
     exit;
  end;


  vtablet := TJSArray(TJSJSON.parseObject(AResponse)); //
  SessionID := string(TJSObject(vtablet)['session']);
  groupid   := string(TJSObject(vtablet)['master']);
  mode      := string(TJSObject(vtablet)['mode']);


  //權限
       ppp := string(TJSObject(vtablet)['ppp']);

       WebSessionStorage1.SetValue('ppp',ppp);
       WebSessionStorage1.SetValue('uname', emailEdit.Text);
       WebSessionStorage1.SetValue('session',SessionID);
       WebSessionStorage1.SetValue('groupid',groupid);
       WebSessionStorage1.SetValue('mode',mode);


       userID1Label.Caption := 'ID:' + emailEdit.Text;
       //UserID2.Caption := 'ID:' + emailEdit.Text;


         toasterDiv.Visible := True;
        xloginDiv.Visible := False;

       // iFrom 啟用

       current_functionLabel.Caption := '庫存 資料查詢';
       sid := SessionID;
       asm

         document.getElementById('runtimepage').src = 'inventory_query.html?id='+ sid ;

       end;

       //set_logs('','' ,'Login');

end;

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  userID1Label := TLabel.Create('userID1');
  current_functionLabel := TLabel.Create('current_function');
  UserID2 := TLabel.Create('permissionsetting');
  WebLabel1 := TLabel.Create('wifisetup');
  WebLabel4 := TLabel.Create('setUser');
  WebLabel3 := TLabel.Create('logos');
  toasterDiv := THTMLDiv.Create('main');
  xloginDiv := THTMLDiv.Create('xlogin');
  LoginButton := TButton.Create('LoginBtn');
  emailEdit := TEdit.Create('email');
  passwordEdit := TEdit.Create('password');
  WebButton1 := TButton.Create('sidebar-toggler');
  LogOutSpan := THTMLSpan.Create('LogOut');
  WebRESTClient1 := TRESTClient.Create(Self);
  WebSessionStorage1 := TSessionStorage.Create(Self);

  userID1Label.BeforeLoadDFMValues;
  current_functionLabel.BeforeLoadDFMValues;
  UserID2.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  toasterDiv.BeforeLoadDFMValues;
  xloginDiv.BeforeLoadDFMValues;
  LoginButton.BeforeLoadDFMValues;
  emailEdit.BeforeLoadDFMValues;
  passwordEdit.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  LogOutSpan.BeforeLoadDFMValues;
  WebRESTClient1.BeforeLoadDFMValues;
  WebSessionStorage1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 742;
    Height := 582;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    userID1Label.SetParentComponent(Self);
    userID1Label.Name := 'userID1Label';
    userID1Label.Left := 256;
    userID1Label.Top := 272;
    userID1Label.Width := 85;
    userID1Label.Height := 18;
    userID1Label.Caption := 'userID1Label';
    userID1Label.ElementFont := efCSS;
    userID1Label.HeightStyle := ssAuto;
    userID1Label.HeightPercent := 100.000000000000000000;
    userID1Label.WidthPercent := 100.000000000000000000;
    current_functionLabel.SetParentComponent(Self);
    current_functionLabel.Name := 'current_functionLabel';
    current_functionLabel.Left := 104;
    current_functionLabel.Top := 288;
    current_functionLabel.Width := 138;
    current_functionLabel.Height := 18;
    current_functionLabel.Caption := 'current_functionLabel';
    current_functionLabel.ElementFont := efCSS;
    current_functionLabel.HeightStyle := ssAuto;
    current_functionLabel.HeightPercent := 100.000000000000000000;
    current_functionLabel.WidthPercent := 100.000000000000000000;
    UserID2.SetParentComponent(Self);
    UserID2.Name := 'UserID2';
    UserID2.Left := 64;
    UserID2.Top := 360;
    UserID2.Width := 75;
    UserID2.Height := 18;
    UserID2.Caption := #21295#20837#12289#20462#25913;
    UserID2.ElementFont := efCSS;
    UserID2.HeightStyle := ssAuto;
    UserID2.HeightPercent := 100.000000000000000000;
    UserID2.WidthPercent := 100.000000000000000000;
    SetEvent(UserID2, Self, 'OnClick', 'UserID2Click');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 281;
    WebLabel1.Top := 344;
    WebLabel1.Width := 60;
    WebLabel1.Height := 18;
    WebLabel1.Caption := #36039#26009#26597#35426;
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel1, Self, 'OnClick', 'WebLabel1Click');
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 424;
    WebLabel4.Top := 368;
    WebLabel4.Width := 75;
    WebLabel4.Height := 18;
    WebLabel4.Caption := #20351#29992#32773#31649#29702;
    WebLabel4.ElementFont := efCSS;
    WebLabel4.HeightStyle := ssAuto;
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel4, Self, 'OnClick', 'WebLabel4Click');
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 305;
    WebLabel3.Top := 400;
    WebLabel3.Width := 60;
    WebLabel3.Height := 18;
    WebLabel3.Caption := #20107#20214#32000#37636;
    WebLabel3.ElementFont := efCSS;
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel3, Self, 'OnClick', 'WebLabel3Click');
    toasterDiv.SetParentComponent(Self);
    toasterDiv.Name := 'toasterDiv';
    toasterDiv.Left := 24;
    toasterDiv.Top := 16;
    toasterDiv.Width := 100;
    toasterDiv.Height := 41;
    toasterDiv.ElementFont := efCSS;
    toasterDiv.Role := '';
    toasterDiv.Visible := False;
    xloginDiv.SetParentComponent(Self);
    xloginDiv.Name := 'xloginDiv';
    xloginDiv.Left := 160;
    xloginDiv.Top := 8;
    xloginDiv.Width := 100;
    xloginDiv.Height := 41;
    xloginDiv.ChildOrder := 1;
    xloginDiv.ElementFont := efCSS;
    xloginDiv.Role := '';
    LoginButton.SetParentComponent(Self);
    LoginButton.Name := 'LoginButton';
    LoginButton.Left := 28;
    LoginButton.Top := 152;
    LoginButton.Width := 96;
    LoginButton.Height := 49;
    LoginButton.Caption := 'Login';
    LoginButton.ChildOrder := 2;
    LoginButton.ElementClassName := 'btn btn-light';
    LoginButton.ElementFont := efCSS;
    LoginButton.HeightStyle := ssAuto;
    LoginButton.HeightPercent := 100.000000000000000000;
    LoginButton.WidthPercent := 100.000000000000000000;
    SetEvent(LoginButton, Self, 'OnClick', 'LoginButtonClick');
    emailEdit.SetParentComponent(Self);
    emailEdit.Name := 'emailEdit';
    emailEdit.Left := 24;
    emailEdit.Top := 72;
    emailEdit.Width := 236;
    emailEdit.Height := 22;
    emailEdit.ChildOrder := 3;
    emailEdit.ElementClassName := 'form-control';
    emailEdit.ElementFont := efCSS;
    emailEdit.HeightStyle := ssAuto;
    emailEdit.HeightPercent := 100.000000000000000000;
    emailEdit.WidthPercent := 100.000000000000000000;
    passwordEdit.SetParentComponent(Self);
    passwordEdit.Name := 'passwordEdit';
    passwordEdit.Left := 24;
    passwordEdit.Top := 112;
    passwordEdit.Width := 236;
    passwordEdit.Height := 22;
    passwordEdit.ChildOrder := 4;
    passwordEdit.ElementClassName := 'form-control';
    passwordEdit.ElementFont := efCSS;
    passwordEdit.HeightStyle := ssAuto;
    passwordEdit.HeightPercent := 100.000000000000000000;
    passwordEdit.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 28;
    WebButton1.Top := 216;
    WebButton1.Width := 45;
    WebButton1.Height := 41;
    WebButton1.ChildOrder := 10;
    WebButton1.ElementClassName := 'btn btn-light';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    LogOutSpan.SetParentComponent(Self);
    LogOutSpan.Name := 'LogOutSpan';
    LogOutSpan.Left := 544;
    LogOutSpan.Top := 243;
    LogOutSpan.Width := 100;
    LogOutSpan.Height := 41;
    LogOutSpan.ChildOrder := 9;
    LogOutSpan.ElementFont := efCSS;
    LogOutSpan.Role := '';
    SetEvent(LogOutSpan, Self, 'OnClick', 'LogOutSpanClick');
    WebRESTClient1.SetParentComponent(Self);
    WebRESTClient1.Name := 'WebRESTClient1';
    SetEvent(WebRESTClient1, Self, 'OnRequestResponse', 'WebRESTClient1RequestResponse');
    WebRESTClient1.Left := 240;
    WebRESTClient1.Top := 152;
    WebSessionStorage1.SetParentComponent(Self);
    WebSessionStorage1.Name := 'WebSessionStorage1';
    WebSessionStorage1.Left := 312;
    WebSessionStorage1.Top := 80;
  finally
    userID1Label.AfterLoadDFMValues;
    current_functionLabel.AfterLoadDFMValues;
    UserID2.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    toasterDiv.AfterLoadDFMValues;
    xloginDiv.AfterLoadDFMValues;
    LoginButton.AfterLoadDFMValues;
    emailEdit.AfterLoadDFMValues;
    passwordEdit.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    LogOutSpan.AfterLoadDFMValues;
    WebRESTClient1.AfterLoadDFMValues;
    WebSessionStorage1.AfterLoadDFMValues;
  end;
end;

end.